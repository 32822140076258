/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { RouteUpdateArgs } from "gatsby";

// You can delete this file if you're not using it

export const onClientEntry = () => {
  require("@justinribeiro/lite-youtube");
};

const w = window as any;

w.routeUpdatesCounter = 0;
export const onPreRouteUpdate = ({
  location,
  prevLocation,
}: RouteUpdateArgs) => {
  if (w.routeUpdatesCounter > 0) {
    w.routeUpdateStart = performance.now();
  }
  w.routeUpdatesCounter += 1;
};
